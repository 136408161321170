<template>
  <div class="container my-3">
    <p class="alert alert-primary text-center">
      Isi form berikut untuk memulai chat
    </p>
    <div>
      <div class="form-group">
        <select class="form-control" v-model="form.departemen">
          <option value="">Pilih departemen</option>
          <option
            :value="dept.id"
            v-for="(dept, key) in departmentStatus"
            :key="key"
          >
            {{ dept.name }} - {{ dept.status }}
          </option>
        </select>
      </div>

      <!-- name field -->
      <div class="form-group">
        <input
          type="text"
          class="form-control"
          placeholder="Masukan nama"
          v-model.trim="$v.form.name.$model"
          :class="$v.form.name.$error ? 'border border-danger' : false"
        />
        <div
          class="error badge badge-danger"
          v-if="$v.form.name.$error && !$v.form.name.required"
        >
          Nama tidak boleh kosong
        </div>
      </div>

      <!-- email field -->
      <div class="form-group">
        <input
          type="email"
          class="form-control"
          :class="$v.form.email.$error ? 'border border-danger' : false"
          placeholder="Masukan email"
          v-model.trim="$v.form.email.$model"
        />
        <div
          class="error badge badge-danger"
          v-if="$v.form.email.$error && !$v.form.email.required"
        >
          Email tidak boleh kosong
        </div>
        <div
          class="error badge badge-danger"
          v-if="$v.form.email.$error && !$v.form.email.minLength"
        >
          Email minimal {{ minLength("email") }} karakter
        </div>
        <div
          class="error badge badge-danger"
          v-if="$v.form.email.$error && !$v.form.email.email"
        >
          Email tidak valid
        </div>
      </div>

      <div class="form-group">
        <input
          type="text"
          class="form-control"
          placeholder="Masukan nomor hp (optional)"
          v-model.trim="$v.form.phone.$model"
          :class="$v.form.phone.$error ? 'border border-danger' : false"
        />
        <div
          class="error badge badge-danger"
          v-if="$v.form.phone.$error && !$v.form.phone.numeric"
        >
          Nomor handphone hanya boleh angka
        </div>
        <div
          class="error badge badge-danger"
          v-if="$v.form.phone.$error && !$v.form.phone.minLength"
        >
          Nomor handphone minimal {{ minLength("phone") }} karakter
        </div>
      </div>

      <div class="d-flex justify-content-between align-items-center">
        <g-recaptcha
          :data-sitekey="sitekey"
          :data-validate="validate"
          :data-callback="onSubmit"
          data-btn-class="btn btn-primary"
          :data-btn-disabled="disableBtn"
        />
      </div>
    </div>
    <p class="position-absolute text-center w-100" style="bottom: 0; left: 0">
      <small class="text-muted">Versi : {{ version }}</small>
    </p>
  </div>
</template>

<script>
import config from "@/common/config/app.config.json";
import { version } from "@/common/general.config";
import { loader } from "@/common/mixins/mix_loader";
import { validationMixin } from "vuelidate";
import { clientCookies } from "@/common/mixins/mix_cookies";
import gRecaptcha from "@finpo/vue2-recaptcha-invisible";
import { email, minLength, required, numeric } from "vuelidate/lib/validators";
import {
  alert,
  request,
  waktu,
  util,
  rwSound,
} from "@/common/mixins/mix_helper";
import { mapGetters } from "vuex";

export default {
  name: "BeforeLogin",
  components: {
    gRecaptcha,
  },
  mixins: [
    validationMixin,
    loader,
    request,
    alert,
    waktu,
    clientCookies,
    util,
    rwSound,
  ],
  props: {
    /**
     * --------------------------------------------------------------
     * untuk memilih / indikator department mana saja yang sedang on
     * --------------------------------------------------------------
     */
    departmentStatus: {
      required: true,
    },
  },
  data() {
    return {
      form: {
        name: "",
        email: "",
        phone: "",
        question: "Halo rumahweb...",
        departemen: "",
      },
      isLogin: "",
      xhr: {
        departemen: [],
      },
      disableBtn: false,
      version: version,
      sitekey: config.reCaptcha.v2.site,
    };
  },
  validations: {
    form: {
      question: {
        required,
        minLength: minLength(5),
      },
      name: {
        required,
      },
      email: {
        required,
        email,
        minLength: minLength(5),
      },
      phone: {
        numeric,
        minLength: minLength(4),
      },
    },
  },
  methods: {
    validate() {
      this.mixSound();
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return false;
      }
      if (this.form.departemen === "") {
        this.$swal("Warning", "Pilih departemen terlebih dahulu.", "warning");
        return false;
      }

      return true;
    },

    /**
     * --------------------------------------------------------------
     * register client && push to message 1st
     * this.mixSound(0); => handle policy chrome
     * --------------------------------------------------------------
     */
    onSubmit: function (reCaptchaToken) {
      if (reCaptchaToken) {
        this.loading();
        this.disableBtn = true;
        let form = this.form;
        form.question = this.escape(form.question);

        let paramRegister = {
          nama: this.escape(form.name),
          email: this.escape(form.email),
          deptid: form.departemen,
          message: form.question,
          referrer: this.getReferrer,
          phone: form.phone,
          token: reCaptchaToken,
        };
        this.$store
          .dispatch("customerRegister", paramRegister)
          .then((res) => {
            if (typeof res.data.token !== "undefined") {
              let token = res.data.token;
              let jwt = this.jwtDecode(token);
              this.$store
                .dispatch("FbAuthLogin", {
                  email: jwt.fb.uname,
                  password: jwt.fb.sandi,
                })
                .then(() => {
                  this.loading();
                  this.addToCookies(res); // set cookies
                  localStorage.setItem(config.rwStorage.customer.token, token); // set token

                  // generate 1st message
                  let _1stMessage = this.generate1stMessage(form.question);
                  if (_1stMessage) {
                    // realtime push / append
                    this.$store.dispatch("MessagePush", _1stMessage);
                    this.$store.dispatch("customerLogin", true);
                    this.$store
                      .dispatch("customerSendMessage", _1stMessage)
                      .then(({ data }) => {
                        // append subthreadid to cookies keperluan export chat if fail
                        this.cookiesAdd({ subid: data.data.subid });
                        this.updateCentangFirstMsg();
                      })
                      .finally(() => {
                        this.loading(false);
                      });
                  } else {
                    this.disableBtn = false;
                    /**
                     * --------------------------------------------------------------
                     * gagal generate message (karna gagal buat cookies)
                     * jadi kirim message default ke agent untuk endchat
                     * --------------------------------------------------------------
                     */
                    let message = `==============bot==============\n Mohon lakukan endchat di thread ini , karena terjadi kegagalan ketika register. Terima kasih.\n==============bot==============`;
                    let errorMessage = this.generate1stMessage(message, true);
                    errorMessage.skipCheckCookies = true;
                    this.$store.dispatch("customerSendMessage", errorMessage);
                    this.cookiesRemove();
                    localStorage.removeItem(config.rwStorage.customer.token);
                    this.$store.dispatch("customerLogin", false);

                    // send log telegram
                    // --- just for report monitoring ---
                    this.$store.dispatch("sendLogAPI", {
                      actor: "client",
                      message: "Failed create a cookies",
                      data: paramRegister,
                      tokenDecode: jwt,
                      sendTelegram: true,
                    });

                    let errorMsgClient = `<div class="text-left">
                  <p>Mohon maaf, browser Anda saat ini belum didukung oleh livechat kami.</p>
                  <p>Untuk melanjutkan chat dengan Orang Rumah, silahkan coba gunakan browser lain.</p>
                  <p>Jika masalah masih terjadi, coba menggunakan perangkat lainnya (handphone / tablet)</p>
                  </div>`;
                    this.$swal({
                      title: "Error",
                      html: errorMsgClient,
                      icon: "error",
                    });
                  }
                })
                .catch(() => {
                  this.disableBtn = false;
                  this.addToCookies(res);
                  localStorage.setItem(config.rwStorage.customer.token, token);
                  let _1stMessage = this.generate1stMessage(form.question);
                  if (_1stMessage) {
                    this.$store.dispatch("MessagePush", _1stMessage);
                    this.$store.dispatch("customerLogin", true);
                    this.$store
                      .dispatch("customerSendMessage", _1stMessage)
                      .then(({ data }) => {
                        /**
                         * --------------------------------------------------
                         * subthread :  export chat if fail
                         * --------------------------------------------------
                         */
                        this.cookiesAdd({
                          subid: data.data.subid,
                          lastMsgId: data.data.messageid,
                        });
                        this.updateCentangFirstMsg();
                      });
                    sessionStorage.setItem(
                      config.rwStorage.customer.fallback,
                      "1"
                    );

                    /**
                     * --------------------------------------------------
                     * init fallback reported
                     * --------------------------------------------------
                     */
                    this.$store.dispatch("sendLogAPI", {
                      actor: "client",
                      message: "Terjadi fallback di thread ini",
                      data: paramRegister,
                      tokenDecode: jwt,
                      sendTelegram: true,
                    });
                  } else {
                    /**
                     * --------------------------------------------------
                     * report gagal generate 1st message untuk fallback process
                     * --------------------------------------------------
                     */
                    this.$store.dispatch("sendLogAPI", {
                      actor: "client",
                      message: "Gagal generate 1st message di fallback jQuery",
                      data: paramRegister,
                      tokenDecode: jwt,
                      sendTelegram: true,
                    });
                  }
                });
            } else {
              this.disableBtn = false;
              this.$store.dispatch("sendLogAPI", {
                actor: "client",
                message: "Missing response token from RestAPI",
                data: paramRegister,
                messageAPI: res,
                sendTelegram: true,
              });
            }
          })
          .catch((error) => {
            this.cookiesRemove();
            let message = this.handleCatchAxios("reg", error, true);
            let errorCustom = ["network error", "timeout exceeded", "errortes"];
            if (errorCustom.includes(message.toLowerCase())) {
              let errorMessage = this.errorMessageRegister(false, message);
              this.$swal({ title: message, html: errorMessage, icon: "error" });
            } else {
              this.$swal({ title: "Error", html: message, icon: "error" });
            }
            this.disableBtn = false;
            // --- just for report monitoring ---
            this.$store.dispatch("sendLogAPI", {
              actor: "client",
              message: "Failed to register API",
              data: paramRegister,
              messageAPI: message,
              sendTelegram: true,
            });
          })
          .finally(() => {
            this.loading(false);
          });
      } else {
        console.warn("failed to get token");
      }
    },

    /**
     * --------------------------------------------------------------
     * check minimal value field
     * --------------------------------------------------------------
     */
    minLength: function (field) {
      let a = this.$v.form[field].$params.minLength;
      if (typeof a.min !== "undefined") {
        return this.$v.form[field].$params.minLength.min;
      }
    },

    /**
     * --------------------------------------------------------------
     * menambahkan data customer ke cookies
     * --------------------------------------------------------------
     */
    addToCookies: function (res) {
      let cookies = {
        customer: {
          id: res.data.id,
          name: res.data.nama,
          email: res.data.email,
        },
        isLogin: true,
        threadid: res.data.threadid,
        rating: { uuid: null, endtime: null },
        pilihDepartemen: { uuid: null },
      };

      let add = this.cookiesAdd(cookies);
      if (add.isOldBrowser) {
        this.$store.dispatch("sendLogAPI", {
          actor: "client",
          message: "This is old browser, not error just for monitoring",
          cookies: add,
          sendTelegram: true,
        });
      }
    },

    /**
     * --------------------------------------------------------------
     * for generate message 1st time
     * --------------------------------------------------------------
     */
    generate1stMessage: function (message, skipCheckCookies = false) {
      let roti = this.cookiesGet();
      if (roti || skipCheckCookies) {
        return {
          time: this.mixEpoch(),
          tipe: "1st-message",
          actor: "customer",
          message: message,
          uuid: this.UUID(),
          status: "pending",
        };
      }
      return false;
    },

    /**
     * --------------------------------------------------
     * custom error
     * 1. gagal request register api (timeout exceed, network error)
     * 2. gagal login firebase
     * --------------------------------------------------
     */
    errorMessageRegister: function () {
      return `
        <div class="text-left">
        Livechat gagal memulai sesi untuk chat Anda.<br>
        Silahkan refresh halaman ini untuk mengulangi sesi chat.<br><br/>
        Jika error masih muncul, silahkan ikuti langkah berikut :<br>
        <ol>
          <li>Gunakan browser lain</li>
          <li>Akses chat langsung ke <a href="https://chat.rumahweb.com">https://chat.rumahweb.com</a></li>
        </ol>
        Jika Anda memiliki akses koneksi lainnya, silahkan coba juga untuk menggunakan koneksi lainnya (handphone atau tablet).
      </div>`;
    },

    /**
     * --------------------------------------------------
     * trigger checklist 1st-message
     * --------------------------------------------------
     */
    // updateCentangFirstMsg() {
    //   this.getAllMessage.filter((all, index) => {
    //     if (all.tipe === "1st-message") {
    //       this.$store.dispatch("MessageUpdate", {
    //         index: index,
    //         record: {
    //           status: "sent",
    //         },
    //       });
    //     }
    //   });
    // },

    // callback(token) {
    //   console.warn("runn", token);
    //   // google recaptcha token , then you can pass to backend with your form data .
    //   if (token) {
    //     console.warn("tokenee", token);
    //   } else {
    //     // if you use data-size show reCAPTCHA , maybe you will get empty token.
    //     console.warn("please check you are not robot");
    //   }
    // },
  },
  computed: {
    getReferrer: function () {
      let ref = window.location.ancestorOrigins;
      if (typeof ref !== "undefined" && typeof ref[0] !== "undefined") {
        return ref[0];
      }
      return null;
    },
    ...mapGetters(["getIsLogin", "getAllMessage"]),
  },
  mounted() {
    this.getIsLogin;
  },
};
</script>

<style scoped lang="scss">
.form-control {
  &:focus {
    box-shadow: none;
  }
}
.error {
  width: 100%;
  margin-top: 0.25rem;
  /*font-size: 80%;*/
  /*color: #c21010;*/
}

.success {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745;
}
</style>
